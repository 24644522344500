<!-- <kpmg-button (handleClick)="getContactPage()" text="Contact Page" appearance="ghost"></kpmg-button> -->
<div class="appShell appShell--stack appShell--scroll">
  <section class="appSlab appSlab--hero">
    <div class="hero__background"></div>
    <header class="appHeader">
      <div class="appHeader__brand">
        <kpmg-logo color="white" size="large"></kpmg-logo>
      </div>
      <nav class="appHeader__nav"></nav>
      <div class="appHeader__actions">
        <kpmg-button
          (handleClick)="dialogContact.showDialog()"
          text="Connect with us"
          appearance="ghost"
          size="medium"
        >
        </kpmg-button>
      </div>
    </header>

    <div class="hero__layout">
      <div class="hero__content">
        <kpmg-title
          text="Tax Design System"
          level="h1"
          appearance="headline"
          size="large"
          class="hero__title"
        >
        </kpmg-title>
        <p class="hero__subtitle">
          Taxes can be taxing. Creating tax software shouldn't be.
        </p>
        <p class="hero__desc">
          Rapidly bring KPMG tax products to market with the Tax Design System —
          a collection of styles, components, and guidelines to streamline
          product design and take the guess-work out of UI development.
        </p>
        <span class="hero__text"> What're you interested in? </span>
        <div class="hero__buttons">
          <a href="#dev" class="hero__button">
            <kpmg-icon [icon]="mdiXml"></kpmg-icon>
            <span class="">Development</span>
          </a>
          <a href="#design" class="hero__button">
            <kpmg-icon [icon]="mdiPalette"></kpmg-icon>
            <span class="">Design</span>
          </a>
          <a href="#resources" class="hero__button">
            <kpmg-icon [icon]="mdiDotsGrid"></kpmg-icon>
            <span class="">Resources</span>
          </a>
        </div>
      </div>
      <div class="hero__image"></div>
    </div>
  </section>

  <!-- Dev Slab
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////-->
  <section class="appSlab appSlab--dev" id="dev">
    <kpmg-title
      text="Development"
      level="h2"
      appearance="headline"
      size="large"
    >
    </kpmg-title>
    <!-- <kpmg-title 
    text="Take the guess-work out of design handoff with our UI Dev Library" 
    size="small" 
    level="span"
    appearance="title">
  </kpmg-title> -->

    <div class="appSlab__cards">
      <article class="card">
        <kpmg-icon
          class="card__icon"
          size="50"
          [icon]="mdiCursorDefaultClickOutline"
        >
        </kpmg-icon>
        <kpmg-title
          text="Documentation"
          size="medium"
          level="h3"
          appearance="title"
          class="card__title"
        >
        </kpmg-title>
        <p class="card__text">
          Explore the UI Dev Library components and API through interactive
          demos.
        </p>

        <kpmg-button
          appearance="ghost"
          [isColorless]="true"
          text="Visit the documentation site"
          size="medium"
          class="card__button"
          hrefLink="https://taxdesignsystem.kpmgservices.tech/dev"
          [openInNew]="true"
        >
        </kpmg-button>
      </article>

      <article class="card">
        <kpmg-icon class="card__icon" size="50" [icon]="mdiAngular">
        </kpmg-icon>
        <kpmg-title
          text="UI Dev Library for Angular"
          size="medium"
          level="h3"
          appearance="title"
          class="card__title"
        >
        </kpmg-title>
        <p class="card__text">
          A package of components, design tokens, and styles to build UIs that
          align to the Tax Design System. Available only for Angular projects.
        </p>

        <kpmg-button
          text="Download the UI Dev Library"
          appearance="ghost"
          [isColorless]="true"
          size="medium"
          class="card__button"
          hrefLink="https://dev.azure.com/kpmgtaxtech/TaxDesignSystem/_git/TaxDesignSystem?path=/angular_library/designSystemWorkspace/releases&_a=contents"
          [openInNew]="true"
        >
        </kpmg-button>
      </article>
    </div>
  </section>

  <!-- Design Slab
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////-->
  <section class="appSlab appSlab--design" id="design">
    <kpmg-title text="Design" level="h2" appearance="headline" size="large">
    </kpmg-title>
    <!-- <kpmg-title 
      text="Craft compelling user experiences with the building blocks of the Tax Design System"
      size="small" 
      level="span" 
      appearance="title">
    </kpmg-title> -->

    <div class="appSlab__cards">
      <article class="card">
        <kpmg-icon class="card__icon" size="50" [icon]="mdiMonitorDashboard">
        </kpmg-icon>
        <kpmg-title
          text="Foundations"
          size="medium"
          level="h3"
          appearance="title"
          class="card__title"
        >
        </kpmg-title>
        <p class="card__text">
          Our guiding principles and core standards for color, accessibility,
          layout, and theming.
        </p>
        <kpmg-button
          text="Read our foundations"
          appearance="ghost"
          [isColorless]="true"
          size="medium"
          hrefLink="https://kpmg.invisionapp.com/dsm/kpmg-us/tax-design-system/nav/5fa7da3c5619f300181d59ea/folder/5e9f8405a5cc9ec31f83d7db"
          [openInNew]="true"
        >
        </kpmg-button>
      </article>

      <article class="card">
        <kpmg-icon class="card__icon" size="50" [icon]="mdiAtom"> </kpmg-icon>
        <kpmg-title
          text="Components"
          size="medium"
          level="h3"
          appearance="title"
          class="card__title"
        >
        </kpmg-title>
        <p class="card__text">
          The research specs, usage notes, and inventory of the Tax Design
          System components.
        </p>
        <kpmg-button
          text="Learn about our components"
          appearance="ghost"
          [isColorless]="true"
          size="medium"
          hrefLink="https://kpmg.invisionapp.com/dsm/kpmg-us/tax-design-system/nav/5fa7da3c5619f300181d59eb/folder/5ec83a21c6189c7c019abe29"
          [openInNew]="true"
        >
        </kpmg-button>
      </article>

      <article class="card">
        <div class="card__icons">
          <img class="card__icon" src="../assets/images/icon-sketch.svg" />
          <img class="card__icon" src="../assets/images/icon-figma.svg" />
          <!-- <img class="card__icon" src="../assets/images/icon-xd.svg" /> -->
        </div>
        <kpmg-title
          text="Design Kit"
          size="medium"
          level="h3"
          appearance="title"
          class="card__title"
        >
        </kpmg-title>
        <p class="card__text">
          Drag-and-drop components into Sketch or Figma to supercharge your
          mockups.
        </p>
        <p>
          <a href="mailto:llintner@kpmg.com" target="_blank">
            Reach out to us
          </a>
          to learn more
        </p>
        <!-- <kpmg-button 
          appearance="secondary" 
          text="Request access" 
          size="medium"
          (handleClick)="shelfDesignKit.showShelf()" 
          class="card__button">
        </kpmg-button> -->
      </article>
    </div>
  </section>

  <!-- Resources Slab
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////-->
  <section class="appSlab appSlab--resources" id="resources">
    <kpmg-title text="Resources" level="h2" appearance="headline" size="large">
    </kpmg-title>
    <kpmg-title
      text="Align with the Tax Design System through a wide-range of additional products, services, and assets."
      size="small"
      level="span"
      appearance="title"
    >
    </kpmg-title>
    <div class="appSlab__cards">
      <!-- PowerBI -->
      <article class="card">
        <kpmg-icon [icon]="mdiPoll" size="50" class="card__icon"> </kpmg-icon>
        <kpmg-title
          text="Power BI"
          size="medium"
          level="h3"
          appearance="title"
          class="card__title"
        >
        </kpmg-title>
        <p class="card__text">
          Jumpstart your Power BI visualizations with our themes, templates, and
          guides.
        </p>
        <div class="card__buttons">
          <kpmg-button
            appearance="link"
            size="small"
            text="Open PowerBI Toolkit"
            [icon]="mdiOpenInNew"
            iconPlacement="right"
            hrefLink="https://spo-global.kpmg.com/sites/GO-KPMGDigitalGateway/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FGO%2DKPMGDigitalGateway%2FShared%20Documents%2FDG%20PowerBI%20Reference%20Info&sortField=Editor&isAscending=true&viewid=00223d59%2D80e1%2D49f2%2Da5e9%2Dc9abdea69c5bp"
            [openInNew]="true"
          >
          </kpmg-button>
        </div>
      </article>

      <!-- Sharepoint -->
      <article class="card">
        <kpmg-icon [icon]="mdiMicrosoftSharepoint" size="50" class="card__icon">
        </kpmg-icon>
        <kpmg-title
          text="SharePoint"
          size="medium"
          level="h3"
          appearance="title"
          class="card__title"
        >
        </kpmg-title>
        <p class="card__text">
          Style SharePoint Online with our 'modern' themes.
        </p>
        <div class="card__buttons">
          <kpmg-button
            appearance="link"
            size="small"
            text="Dark theme for SharePoint (.ps1)"
            iconPlacement="left"
            hrefLink="../../assets/sharepoint/SharePoint-KPMG-Dark-Theme.zip"
          >
          </kpmg-button>
          <kpmg-button
            appearance="link"
            size="small"
            text="Light theme for SharePoint (.ps1)"
            iconPlacement="left"
            hrefLink="../../assets/sharepoint/SharePoint-KPMG-Light-Theme.zip"
          >
          </kpmg-button>
          <!-- <kpmg-button 
            appearance="flat" 
            size="small" 
            text="How to use Modern SharePoint Themes" 
            [icon]="mdiArrowCollapseDown"
            iconPlacement="left">
          </kpmg-button> -->
        </div>
      </article>

      <!-- Fonts -->
      <article class="card">
        <kpmg-icon [icon]="mdiDotsTriangle" size="50" class="card__icon">
        </kpmg-icon>
        <kpmg-title
          text="Assets"
          size="medium"
          level="h3"
          appearance="title"
          class="card__title"
        >
        </kpmg-title>
        <p class="card__text">Useful fonts and images</p>
        <div class="card__buttons">
          <kpmg-button
            appearance="link"
            size="small"
            text="KPMG font"
            iconPlacement="left"
            hrefLink="../../assets/fonts/KPMG Font.zip"
          >
          </kpmg-button>
          <kpmg-button
            appearance="link"
            size="small"
            text="Roboto font"
            iconPlacement="left"
            hrefLink="../../assets/fonts/Roboto Font.zip"
          >
          </kpmg-button>
          <kpmg-button
            appearance="link"
            size="small"
            text="KPMG logo"
            iconPlacement="left"
            hrefLink="../../assets/logo/KPMG Logo.zip"
          >
          </kpmg-button>
        </div>
      </article>
    </div>
  </section>

  <footer class="appSlab appSlab--footer">
    <kpmg-logo color="white" size="large"></kpmg-logo>
    <div class="appSlab__cards">
      <article class="card card--dev">
        <kpmg-title
          text="Development"
          class="footer__title"
          size="small"
          level="h3"
          appearance="title"
        >
        </kpmg-title>

        <kpmg-button
          appearance="link"
          size="medium"
          text="Documentation Site"
          [openInNew]="true"
          hrefLink="http://taxdesignsystem.us.kworld.kpmg.com/dev/"
        >
        </kpmg-button>
        <kpmg-button
          appearance="link"
          size="medium"
          text="Git Repo"
          [openInNew]="true"
          hrefLink="https://dev.azure.com/kpmgtaxtech/_git/TaxDesignSystem"
        >
        </kpmg-button>
        <kpmg-button
          text="Download the UI Dev Library"
          appearance="link"
          size="medium"
          hrefLink="https://dev.azure.com/kpmgtaxtech/TaxDesignSystem/_git/TaxDesignSystem?path=/angular_library/designSystemWorkspace/releases&_a=contents"
          [openInNew]="true"
        >
        </kpmg-button>
        <kpmg-button
          appearance="link"
          size="medium"
          text="Legacy Docs"
          [openInNew]="true"
          hrefLink="http://taxdesignsystem.us.kworld.kpmg.com/dev/v1/"
        >
        </kpmg-button>
      </article>

      <article class="card card--design">
        <kpmg-title
          text="Design"
          class="footer__title"
          size="small"
          level="h3"
          appearance="title"
        >
        </kpmg-title>
        <kpmg-button
          appearance="link"
          size="medium"
          text="Foundations"
          [openInNew]="true"
          hrefLink="https://kpmg.invisionapp.com/dsm/kpmg-us/tax-design-system/nav/5fa7da3c5619f300181d59ea/folder/5e9f8405a5cc9ec31f83d7db"
        >
        </kpmg-button>
        <kpmg-button
          appearance="link"
          size="medium"
          text="Components"
          [openInNew]="true"
          hrefLink="https://kpmg.invisionapp.com/dsm/kpmg-us/tax-design-system/nav/5fa7da3c5619f300181d59eb/folder/5ec83a21c6189c7c019abe29"
        >
        </kpmg-button>
      </article>

      <article class="card card--leadership">
        <kpmg-title
          text="Leadership"
          class="footer__title"
          size="small"
          level="h3"
          appearance="title"
        >
        </kpmg-title>
        <ul class="card__list">
          <li>
            <kpmg-title
              text="Brad Brown"
              class="leadership__name"
              size="tiny"
              level="span"
              appearance="title"
            >
            </kpmg-title>
            <span class="leadership__title"> Partner, Tax </span>
            <span class="leadership__role">
              Chief Technology Officer, Tax
            </span>
          </li>
          <li>
            <kpmg-title
              text="Lars Lintner"
              class="leadership__name"
              size="tiny"
              level="span"
              appearance="title"
            >
            </kpmg-title>
            <span class="leadership__title">
              Senior Director UX/UI, Ignition Tax Product
            </span>
            <span class="leadership__role"> Head of User Experience </span>
          </li>
        </ul>
      </article>

      <article class="card card--copyright">
        <p>
          &copy; {{ currentYear }} KPMG LLP, a Delaware limited liability partnership and a
          member firm of the KPMG global organization of independent member
          firms affiliated with KPMG International Limited, a private English
          company limited by guarantee. All rights reserved.
        </p>
      </article>
    </div>
  </footer>
</div>

<kpmg-dialog
  #dialogContact
  title="Connect with us"
  size="medium"
  [bodyTemplate]="dialogContactBody"
  [footerTemplate]="dialogContactFooter"
  [isDraggable]="true"
  [isResizable]="true"
  [clickOnBackdrop]="true"
  [isCloseable]="true"
>
</kpmg-dialog>
<ng-template #dialogContactBody>
  <div class="dialog__contact">
    <a
      class="tile"
      href="https://teams.microsoft.com/l/team/19%3a19d517c1dadb47e6b98e7b3154dd2676%40thread.tacv2/conversations?groupId=dcdef14b-af00-454d-a24b-aac19789b86a&tenantId=deff24bb-2089-4400-8c8e-f71e680378b2"
      target="_blank"
    >
      <kpmg-icon [icon]="mdiMicrosoftTeams"></kpmg-icon>
      <span>Teams</span>
      <p>Join our Team channels to get up-to-date info and support</p>
    </a>

    <a class="tile" href="mailto:llintner@kpmg.com" target="_blank">
      <kpmg-icon [icon]="mdiEmail"></kpmg-icon>
      <span>Email</span>
      <p>Get in touch with Lars Lintner, Senior Director of UX</p>
    </a>

    <a
      class="tile"
      href="https://kpmg.workplace.com/groups/450223495883747"
      target="_blank"
    >
      <kpmg-icon [icon]="mdiFacebookWorkplace"></kpmg-icon>
      <span>Workplace</span>
      <p>Follow us on Workplace for updates</p>
    </a>
  </div>
</ng-template>
<ng-template #dialogContactFooter> </ng-template>
