<div class="appShell appShell--stack appShell--scroll">
    <header class="appHeader">
        <div class="appHeader__brand">
          <kpmg-logo color="white" size="large"></kpmg-logo>

        </div>
        <nav class="appHeader__nav">
            <kpmg-button (handleClick)="getHomePage()" text="Home Page" appearance="ghost"></kpmg-button>

        </nav>
        <div class="appHeader__actions">
        </div>
      </header>


<iframe src="https://forms.monday.com/forms/embed/07062325bb3385ae0d3cdb5995afa1f2" width="650" height="500" style="width: 100vw; border: 0; box-shadow: 5px 5px 56px 0px rgba(0,0,0,0.25);"></iframe>