import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { mdiArrowCollapseDown, mdiPalette, mdiOpenInNew, mdiDotsGrid, mdiDotsTriangle, mdiXml, mdiAtom, mdiEmail, mdiFacebookWorkplace, mdiMonitorDashboard, mdiAngular, mdiCursorDefaultClickOutline, mdiPoll, mdiMicrosoftSharepoint, mdiFormatFont, mdiMicrosoftTeams } from '@mdi/js'
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  mdiArrowCollapseDown: string = mdiArrowCollapseDown;
  mdiAtom: string = mdiAtom;
  mdiXml: string = mdiXml;
  mdiPalette: string = mdiPalette;
  mdiDotsGrid: string = mdiDotsGrid;
  mdiDotsTriangle: string = mdiDotsTriangle;
  mdiOpenInNew: string = mdiOpenInNew;
  mdiMonitorDashboard: string = mdiMonitorDashboard;
  mdiAngular: string = mdiAngular;
  mdiCursorDefaultClickOutline: string = mdiCursorDefaultClickOutline;
  mdiPoll: string = mdiPoll;
  mdiMicrosoftSharepoint: string = mdiMicrosoftSharepoint;
  mdiMicrosoftTeams: string = mdiMicrosoftTeams;
  mdiFormatFont: string = mdiFormatFont;
  mdiEmail: string = mdiEmail;
  mdiFacebookWorkplace: string = mdiFacebookWorkplace;
  currentYear = new Date().getFullYear();
  constructor(public router: Router) { }

  ngOnInit() {
  }
  getContactPage(){
    this.router.navigateByUrl('/contact');
  }

  urlDecoder(url) {
    return decodeURIComponent(url)
  }
}
